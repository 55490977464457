/**
 * Si occupa di creare elementi editabili all'interno di un elemento jQuery
 *
 * @param options Object
 */
var editable = function (options) {

    var editable = this;

    this.element = options.element;
    this.type = options.type;
    this.url = options.url;
    this.autocomplete = options.autocomplete;
    this.id = options.id;
    this.index = options.index;
    this.value = options.value;
    this.bind = options.bind || 'change';
    this.mask = options.mask;
    this.nullable = options.nullable;
    this.nullValue = options.nullValue;
    this.block = options.block || false;
    this.spinner = options.spinner || false;
    this.done = options.done || function () {};
    this.fail = options.fail || function () {};
    this.params = options.params || function () {};
    this.$tr = options.tr;


    var $spinner = undefined;
    setTimeout(function () {
        // Spinner sull'intera riga!
        var width = editable.$tr.width();
        var height = editable.$tr.height();
        $spinner = $('<div style="display: none;"><i class="ace-icon fa fa-spinner fa-spin blue" style="font-size: 20px; line-height: ' + height + 'px"></i></div>');

        var top = editable.$tr.position().top;
        var left = editable.$tr.position().left;

        $spinner.css({
            position: 'absolute',
            'z-index': 99999,
            'background-color': 'rgba(170, 170, 170, 0.7)',
            top: top,
            left: left,
            width: width,
            height: height,
            'text-align': 'center',
            'vertical-align': 'middle'
        });
    }, 200);

    this.$element = undefined;

    this.make = function () {
        switch (editable.type) {
            case 'select':
                this.makeSelect();
                break;
            case 'bool':
                this.makeBool();
                break;
            case 'date':
                this.makeDate();
                break;
            case 'datetime':
                this.makeDateTime();
                break;
            case 'textarea':
                this.makeTextArea();
                break;
            default: // text
                this.makeText();
                break;
        }
    };

    this.makeDate = function () {
        editable.makeText();

        editable.$element.datetimepicker({
            dayOfWeekStart: 1,
            format: "d/m/Y",
            closeOnDateSelect: true,
            timepicker: false,
            scrollInput: false
        });
    };

    this.makeDateTime = function () {
        editable.makeText();

        var date = new Date(parseInt(moment().format("Y")), 01, 01, 06, 45, 00), interval = 15, arrTime = [];
        for (var i = 0; i < (12 * 4); i++) {
            date.setMinutes(date.getMinutes() + interval);
            arrTime.push(date.getHours() + '.' + date.getMinutes());
        }

        editable.$element.datetimepicker({
            dayOfWeekStart: 1,
            format: "d/m/Y H:i",
            step: 30,
            closeOnTimeSelect: true,
            scrollInput: false,
            allowTimes: arrTime
        });
    };

    this.makeText = function () {
        var $text = $('<input class="data-dt-focus" style="width: 95%;" type="text" value="' + editable.value + '">');

        $text.bind(editable.bind, function () {
            var value = $(this).val();

            app.block(editable.block);
            if (editable.spinner)
                $spinner.show();
            var params = {index: editable.index, value: value, id: editable.id};
            var extra = editable.params;
            $.post(editable.url, $.extend({}, params, extra))
                .done(function (data) {
                    editable.done(data, $text, $spinner);
                })
                .fail(function (data) {
                    editable.fail(data, $text, $spinner);
                });
        });

        $text.bind('keyup', function () {
            $text.css('border-color', '#F59942');
        });

        // input mask
        if (editable.mask) {
            $text.inputmask(editable.mask.mask, (editable.mask.options || undefined));
        }

        editable.element.html($text);

        setTimeout(function () {
            if (editable.spinner)
                editable.element.append($spinner);
        }, 300);

        editable.$element = $text;
    };

    this.makeTextArea = function () {
        var $textarea = $('<a href="#" class="editable editable-pre-wrapped editable-click">' + editable.value + '</a>');

        $textarea.editable({
            type: 'textarea',
            emptytext: '&nbsp;&nbsp;&nbsp;',
            success: function (data, value) {
                var params = {index: editable.index, value: value, id: editable.id};
                var extra = editable.params;

                app.block(editable.block);
                if (editable.spinner)
                    $spinner.show();

                $.post(editable.url, $.extend({}, params, extra))
                    .done(function (data) {
                        editable.done(data, $textarea, $spinner);
                    })
                    .fail(function (data) {
                        editable.fail(data, $textarea, $spinner);
                    });
            }
        });

        editable.element.html($textarea);

        setTimeout(function () {
            if (editable.spinner)
                editable.element.append($spinner);
        }, 300);

        editable.$element = $textarea;
    };

    this.makeSelect = function () {
        var $select = $('<select class="select2" data-old-value="' + ((editable.value !== undefined) ? editable.value : "") + '" style="width: 95%;" data-placeholder=" "><option value=""></option></select>');

        if (typeof editable.value != 'undefined') {
            var $option = $('<option value="' + editable.value + '" selected>' + editable.value + '</option>')
            $select.html($option);
        }

        var send = function (value) {
            app.block(editable.block);
            if (editable.spinner)
                $spinner.show();
            var params = {index: editable.index, value: value, id: editable.id};
            var extra = editable.params;
            $.post(editable.url, $.extend({}, params, extra))
                .done(function (data) {
                    editable.done(data, $select, $spinner);
                })
                .fail(function (data) {
                    editable.fail(data, $select, $spinner);
                });
        };

        $select.bind(editable.bind, function () {
            var value = $(this).val();

            send(value);
        });

        $select.bind("select2:unselecting", function () {
            $(this).data('state', 'unselected');

            if (editable.nullable)
                send(editable.nullValue);
        });

        editable.element.html($select);

        setTimeout(function () {
            if (editable.spinner)
                editable.element.append($spinner);
        }, 300);

        $select.select2({
            ajax: {
                url: editable.autocomplete,
                dataType: 'json',
                delay: 100,
                type: 'POST',
                data: function (params) {
                    return {
                        search: params.term, // search term
                        page: params.page
                    };
                },
                processResults: function (data, params) {
                    // parse the results into the format expected by Select2
                    // since we are using custom formatting functions we do not need to
                    // alter the remote JSON data, except to indicate that infinite
                    // scrolling can be used
                    params.page = params.page || 1;

                    return {
                        results: data.items
                    };
                },
                cache: true
            },
            containerCssClass: "data-dt-focus",
            tags: true,
            escapeMarkup: function (markup) {
                return markup;
            }, // let our custom formatter work
            minimumInputLength: 0,
            allowClear: ((typeof editable.nullable != 'undefined') ? editable.nullable : true),
            cache: true,
            templateResult: function (data) {
                return data.text;
            },
            templateSelection: function (data) {
                if (typeof data.label == 'undefined')
                    data.label = data.text;
                return data.label;
            }
        }).on("select2:open", function(e) {
            if ($(this).data('state') === 'unselected') {
                $(this).removeData('state');

                var self = $(this);
                setTimeout(function() {
                    self.select2('close');
                }, 1);
            }
        });
    };

    this.makeBool = function () {
        var value = editable.value;
        if (value === "false") value = false;
        if (value === "true") value = true;
        if (value === "0") value = false;
        if (value === "1") value = true;

        var icon = value ? "check" : "times";
        var color = value ? "green" : "red";
        var $bool = $('<button type="button" data-value="' + value + '" class="data-dt-focus btn btn-xs btn-link"><i class="fa fa-' + icon + " " + color + '"></i></button>');

        $bool.bind(editable.bind, function () {
            app.block(editable.block);
            if (editable.spinner)
                $spinner.show();

            var value = $(this).attr("data-value");
            if (value === "true") value = false;
            if (value === "false") value = true;
            // per validazione Laravel
            value = (value) ? 1 : 0;
            var params = {index: editable.index, value: value, id: editable.id};
            var extra = editable.params;

            $.post(editable.url, $.extend({}, params, extra))
                .done(function (data) {
                    editable.done(data, $bool, $spinner);
                })
                .fail(function (data) {
                    editable.fail(data, $bool, $spinner);
                });
        });

        editable.element.html($bool);

        setTimeout(function () {
            if (editable.spinner)
                editable.element.append($spinner);
        }, 300);
    };

};