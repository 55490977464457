/**
 * Error Handler per chiamate ajax
 *
 * @type {{init: errorHandler.init}}
 */
var errorHandler = {

    init:function(){
        $(document).ajaxComplete(function myErrorHandler(event, xhr, ajaxOptions, thrownError) {
            var page = "";

            switch (xhr.status){
                case 0:
                case 200:
                    return;
                case 422:
                    return;
                case 401:
                    app.href("/login");
                    return;
                case 403:
                    page = app.page403;
                    break;
                case 404: // not found
                    page = app.page403;
                    break;
                default:
                    page = app.pageError;
            }

            if(!parseInt(app.debug)) {
                $("#page-content-section").html(page);
            }
        });
    }

};