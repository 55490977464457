var device = function () {

    this.bindEvents = function (dt)
    {
        $('[data-interaction=maintenance]').unbind('click').bind('click', function () {
            var id = $(this).attr('data-id');
            var action = $(this).attr('data-action');
            var status = $(this).attr('data-status');

            var url = dt.baseUrl + '/' + id + '/maintenance';

            app.block(1);
            $.post(url, {action: action, status: status})
                .done(function (data) {
                    if (data.response) {
                        app.success("", data.message);
                    } else {
                        app.warning("", data.message);
                    }
                    app.block(0);
                })
                .fail(function () {
                    app.block(0);
                    app.error("Errore AJAX!");
                });
        }).tooltip();
    };

    this.bindEvents();

};