var activation_code = function ()
{

    this.bindEvents = function ()
    {
        $('#lifetime_license').unbind('click').bind('click', function () {
            if ($(this).is(':checked')) {
                $('#expiration_date').attr('disabled', 'disabled');
                $('label[for=expiration_date]').find('.required').addClass('hide');
            } else {
                $('#expiration_date').removeAttr('disabled');
                $('label[for=expiration_date]').find('.required').removeClass('hide');
            }
        });

        $('[data-interaction=print]').unbind('click').bind('click', function () {
            var url = $(this).attr('data-url');

            app.block(1);
            $.post(url)
                .done(function (data) {
                    if (data.response) {
                        window.open(data.message + "&d=1", "_blank");
                    } else {
                        app.warning("", data.message);
                    }
                    app.block(0);
                })
                .fail(function () {
                    app.block(0);
                });
        });
    };

    this.bindEvents();

};