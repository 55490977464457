var formatter = {

    bool: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data ? '<i class="fa fa-check green"></i>' : '<i class="fa fa-times red"></i>';
    },

    euro: function (table, td, cell_data, row_data, row_index, col_index) {
        ret = cell_data + " €";
        return ret;
    },

    tools: function (table, td, cell_data, row_data, row_index, col_index) {
        var start = '<button data-rel="tooltip" data-interaction="maintenance" data-id="' + cell_data + '" data-action="maintenance_mode" data-status="1" title="Start maintenance mode" class="btn btn-xs btn-warning"><i class="fa fa-pause"></i></button>&nbsp;';
        var stop = '<button data-rel="tooltip" data-interaction="maintenance" data-id="' + cell_data + '" data-action="maintenance_mode" data-status="0" title="Stop maintenance mode" class="btn btn-xs btn-success"><i class="fa fa-play"></i></button>&nbsp;';
        var kioskoff = '<button data-rel="tooltip" data-interaction="maintenance" data-id="' + cell_data + '" data-action="kiosk_mode" data-status="0" title="Stop kiosk mode" class="btn btn-xs btn-warning"><i class="fa fa-times"></i></button>&nbsp;';
        var kioskon = '<button data-rel="tooltip" data-interaction="maintenance" data-id="' + cell_data + '" data-action="kiosk_mode" data-status="1" title="Start kiosk mode" class="btn btn-xs btn-success"><i class="fa fa-check"></i></button>&nbsp;';
        var reset = '<button data-rel="tooltip" data-interaction="maintenance" data-id="' + cell_data + '" data-action="flush_db" data-status="1" title="Reset device" class="btn btn-xs btn-danger"><i class="fa fa-eraser"></i></button>';

        return start + stop + kioskoff + kioskon + reset;
    },

    print: function (table, td, cell_data, row_data, row_index, col_index) {
        return '<button class="btn btn-xs btn-primary btn-white" data-interaction="print" data-url="' + table.baseUrl + "/" + cell_data + "/print" + '"><i class="fa fa-print blue"></i></button>';
    }

};